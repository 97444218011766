import { createRouter, createWebHashHistory } from 'vue-router';

import Main from './components/Main.vue'
import Signup from './components/Signup.vue'
import TAndC from './components/TAndC.vue'
import Communicate from './components/Communicate.vue'

export const routes = [
    {
        path: "/",
        name: "Home",
        component: Main
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup
    }, {
        path: '/tandc',
        name: 'TAndC',
        component: TAndC
    }, {
        path: '/communicate',
        name: 'communicate',
        component: Communicate
    }

];

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
