<template>
  <div>
    <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 800px        
      ">
      <div class="text">
        <p>
          <input
            type="text"
            id="iName"
            name="iName"
            class="textInput"
            :placeholder="$t('app.signup_userName')"
            title="Please enter your first and last name."
            v-model="userName"
          />
        </p>
        <p>
          <input
            type="email"
            id="iEmail"
            name="iEmail"
            class="textInput"
            title="Please enter the e-mail address that is associated with your Field Manager account."
            v-model="userEmail"
            :placeholder="$t('app.signup_userMail')"
          />
        </p>
        <p>
          <input
            type="phone"
            id="iPhone"
            name="iPhone"
            class="textInput"
            title="Please enter your telefone number."
            v-model="userPhoneNumber"
            :placeholder="$t('app.signup_phone')"
          />
        </p>
        <p>
          <input
            type="text"
            id="iNIP"
            name="iNIP"
            title="Please enter your tax account number."
            class="textInput"
            v-model="userTaxAccountNumber"
            :placeholder="$t('app.signup_taxAccountNumber')"
          >
        </p>
      </div>

      <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 600px;
      ">
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          style="background: #fbfbfb; background-color: #fbfbfb; width: 100%"
        >
          <tbody>
            <tr>
              <td style="direction: ltr;
                          font-size: 0px;
                          padding: 0px 0;
                          text-align: center;
                          vertical-align: top;">
                <div
                  class="mj-column-per-100 outlook-group-fix"
                  style="font-size: 13px;
                            text-align: left;
                            direction: ltr;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="vertical-align: top"
                    width="100%"
                  >
                    <tr>
                      <td style="font-size: 0px;
                                  padding: 10px 0px;
                                  word-break: break-word;">
                        <p style="border-top: dotted 2px #941c80;
                                    font-size: 1;
                                    margin: 0px auto;
                                    width: 100%;"></p>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 800px        
      ">
      <form>
        <div class="checkboxes">
          <label for="subscribeParticipateInProgram">
            <input
              type="checkbox"
              id="subscribeParticipateInProgram"
              name="cbParticipateInProgram"
              value="participateInProgram"
              v-model="accepts_xRFC_TAndC"
            />
            <span>{{$t('app.signup_accept_xRFC')}}</span>
          </label>

          <label for="subscribeXFMProgram">
            <input
              type="checkbox"
              id="subscribeXFMProgram"
              name="cbXFMProgram"
              value="xFMProgram"
              v-model="accepts_xFM_TAndC"
            />
            <span>{{$t('app.signup_accept_xFM')}}</span>
          </label>

          <label for="subscribeGDPR">
            <input
              type="checkbox"
              id="subscribeGDPR"
              name="cbGDPR"
              value="GDPR"
              v-model="accepts_dpa"
            />
            <span>{{$t('app.signup_accept_GDPR')}}</span>
          </label>

          <label for="subscribeInformationProcessing">
            <input
              type="checkbox"
              id="subscribeInformationProcessing"
              name="cbInformationProcessing"
              value="informationProcessing"
              v-model="accept_marketingDP"
            />
            <span>{{$t('app.signup_accept_Marketing')}}</span>
          </label>
        </div>
      </form>
    </div>

    <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #fbfbfb; background-color: #fbfbfb; width: 100%"
      >
        <tbody>
          <tr>
            <td style="direction: ltr;
                          font-size: 0px;
                          padding: 0px 0;
                          text-align: center;
                          vertical-align: top;">
              <div
                class="mj-column-per-100 outlook-group-fix"
                style="font-size: 13px;
                            text-align: left;
                            direction: ltr;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;"
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <tr>
                    <td style="font-size: 0px;
                                  padding: 10px 0px;
                                  word-break: break-word;">
                      <p style="border-top: dotted 2px #941c80;
                                    font-size: 1;
                                    margin: 0px auto;
                                    width: 100%;"></p>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 10px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div style="margin: 0px auto; max-width: 600px">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="width: 100%"
                >
                  <tbody>
                    <tr style="align: center;">
                      <td style="
                          direction: ltr;
                          font-size: 0px;
                          padding: 10px 20px;
                          text-align: center;
                          vertical-align: top;
                        ">
                        <div
                          class="mj-column-per-100 outlook-group-fix"
                          style="
                            font-size: 13px;
                            text-align: left;
                            direction: ltr;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                          "
                        >
                          <table
                            class="tableCenter"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            style="vertical-align: top; horizontal-align: center;"
                            width="50%"
                          >
                            <tr>
                              <td
                                align="center"
                                bgcolor="#039be6"
                                role="presentation"
                                :class="registerReady"
                                style="border: none;
                                        border-radius: 3px;
                                        cursor: auto;
                                        padding: 10px 25px;"
                                valign="middle"
                              >
                                <button
                                  :class="registerReady"
                                  style="font-family: Ubuntu, Helvetica, Arial, sans-serif;
                                  font-size: 13px;
                                  font-weight: normal;
                                  line-height: 120%;
                                  margin: 0;
                                  border-width: 0px;
                                  text-decoration: none;
                                  text-transform: none;"
                                  :disabled="!registerButtonEnabled"
                                  @click="signupUser()"
                                >{{$t('app.button_register')}}</button>

                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script>
import apgClientFactory from "aws-api-gateway-client";
import config_def from "../modules/env_def";
import { env } from "../modules/env_set";

const config = config_def[env.toUpperCase()];
var apigClient = apgClientFactory.newClient(config);

export default {
  components: {},
  data: function() {
    return {
      userName: null,
      userEmail: null,
      userPhoneNumber: null,
      userTaxAccountNumber: null,
      accepts_xFM_TAndC: false,
      accepts_xRFC_TAndC: false,
      accepts_dpa: false,
      accept_marketingDP: false
    };
  },
  methods: {
    validateUserInput: function() {
      if (!this.registerButtonEnabled) return;
      if (this.userName == null || this.userName.trim() == "") {
        alert("Please provide your name");
      }
      if (this.userEmail == null || this.userEmail.trim() == "") {
        alert("Please provide your xarvio Field Manager E-mail");
      }
      if (this.userPhoneNumber == null || this.userPhoneNumber.trim() == "") {
        alert("Please provide your phone number");
      }
      if (
        this.userTaxAccountNumber == null ||
        this.userTaxAccountNumber.trim() == ""
      ) {
        alert("Please provide your tax account number");
      }

      return true;
    },
    signupUser: function() {
      this.validateUserInput();

      var additionalParams = {};

      var body = {
        userMail: this.userEmail,
        userName: this.userName,
        userPhoneNumber: this.userPhoneNumber,
        userTaxAccount: this.userTaxAccountNumber,
        "accepts-xRFC-TermsAndConditions": this.accepts_xRFC_TAndC.toString(),
        "accepts-GDPR": this.accepts_dpa.toString(),
        "accepts-MarketingAndPromotionalContent": this.accept_marketingDP.toString(),
        "accepts-xFM-TermsAndConditions": this.accepts_xFM_TAndC.toString()
      };

      const pathParams = {};
      // Template syntax follows url-template https://www.npmjs.com/package/url-template
      const pathTemplate = "/xrfc";
      const method = "POST";

      apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function(error) {
          //This is where you would put a success callback
          alert("Thank you for Accepting the Terms and Conditions");
          console.log(error);
          self.close();
        })
        .catch(function(error) {
          //This is where you would put an error callback
          alert("Something went wrong please contact us at xrfc@xarvio.info");
          console.log(error);
        });
    }
  },
  computed: {
    registerButtonEnabled: function() {
      // marketing is optional
      const enabled =
        this.accepts_xFM_TAndC && this.accepts_xRFC_TAndC && this.accepts_dpa;
      return enabled;
    },
    registerReady: function() {
      return this.registerButtonEnabled ? "enabled" : "disabled";
    }
  },
  mounted: function() {
    if ("bare" in this.$route.query) {
      this.$store.commit("changeIsBare", this.$route.query.bare);
    } else {
      this.$store.commit("changeIsBare", false);
    }
  }
};
</script>

<style scoped>
.tableCenter {
  margin-left: auto;
  margin-right: auto;
}

.name {
  font-weight: bold;
  text-align: right;
  padding-right: 5px;
  color: black;
}

.light-name {
  text-align: right;
  padding-right: 5px;
  color: black;
}

.text .textInput {
  width: 200px;
}

.checkboxes label {
  display: block;
  padding-right: 5px;
  padding-left: 30px;
  text-indent: -10px;
  padding-bottom: 5px;
}

.checkboxes input {
  vertical-align: middle;
}

.checkboxes label span {
  vertical-align: middle;
  flex: 1;
  text-align: left;
  font-family: roboto, helvetica, arial, sans-serif;
  font-size: 13px;
  line-height: 0;
  color: rgb(82, 82, 82);
}

.enabled {
  background: #039be6;
}

.disabled {
  background: #bababa;
}
</style>