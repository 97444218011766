<template>
  <div>
    <div id="nav">
      <ul class="nav">
        <li>
          <a
            href="#"
            @click="setLocale('pl')"
          >
            <img
              :src="flags['pl']"
              height="50px"
              width="50px"
              :class="cssClass('pl')"
            />
          </a>
        </li>
        <li>
          <a
            href="#"
            @click="setLocale('en')"
          >
            <img
              :src="flags['gb-eng']"
              height="50px"
              width="50px"
              :class="cssClass('en')"
            />
          </a>
        </li>

        <!-- <li>
          <a
            href="#"
            @click="setLocale('de')"
          >
            <img
              :src="flags['de']"
              height="50px"
              width="50px"
              :class="cssClass('de')"
            />
          </a>
        </li> -->
      </ul>
    </div>
    <theheader v-if="showHeader"></theheader>
    <router-view>
    </router-view>
    <thefooter v-if="showFooter"></thefooter>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

import de_flag from "./../node_modules/svg-country-flags/svg/de.svg";
import pl_flag from "./../node_modules/svg-country-flags/svg/pl.svg";
import gb_en_flag from "./../node_modules/svg-country-flags/svg/gb-eng.svg";

export default {
  name: "App",
  components: {
    theheader: Header,
    thefooter: Footer
  },
  data: function() {
    return {
      flags: { de: de_flag, pl: pl_flag, "gb-eng": gb_en_flag }
    };
  },
  computed: {
    showHeader: function() {
      return this.$store.state.showHeader;
    },
    showFooter: function() {
      return this.$store.state.showFooter;
    }
  },
  methods: {
    cssClass: function(lang) {
      if (lang === this.$root.$i18n.locale) {
        return "country-icon as-toggle active";
      } else {
        return "country-icon as-toggle";
      }
    },
    setLocale: function(locale) {
      this.$root.$i18n.locale = locale;
    },
    onBare: function(is_bare) {
      console.log("Hello Is-Bare: ", is_bare);
    }
  },
  watch: {},
  created: function() {}
};
</script>

<style>
.country-icon {
  width: 20px;
  height: auto;
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #dee2e6;
  box-shadow: 0px 1px 3px rgba(24, 29, 38, 0.1);
}
.country-icon.as-toggle {
  margin-top: 5px;
}
.locale-name {
  display: inline-block;
  vertical-align: baseline;
}

/* we could add a css animation  */
.country-icon:hover {
  background-color: black;
}

.country-icon.as-toggle.active {
  border: 2px solid #941c80;
}

@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700";
@import "https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700";

@media only screen and (max-width: 480px) {
  @-ms-viewport {
    width: 320px;
  }

  @viewport {
    width: 320px;
  }
}

@media only screen and (min-width: 480px) {
  .mj-column-per-100 {
    width: 100% !important;
    max-width: 100%;
  }

  .mj-column-px-600 {
    width: 600px !important;
    max-width: 600px;
  }

  .mj-column-px-400 {
    width: 400px !important;
    max-width: 400px;
  }

  .mj-column-per-50 {
    width: 50% !important;
    max-width: 50%;
  }

  .mj-column-per-33 {
    width: 33.333333333333336% !important;
    max-width: 33.333333333333336%;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#outlook a {
  padding: 0;
}

.ReadMsgBody {
  width: 100%;
}

.ExternalClass {
  width: 100%;
}

.ExternalClass * {
  line-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

table,
td {
  border-collapse: collapse;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
}

img {
  border: 0;
  height: auto;
  line-height: 100%;
  outline: none;
  text-decoration: none;
  -ms-interpolation-mode: bicubic;
}

p {
  display: block;
  margin: 13px 0;
}

body {
  margin: 0px;
  font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica Neue World 75 Bold",
    Helvetica, Arial, sans-serif;
}

.basfheader {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  width: 100%;
  height: 20px;
  color: #65ac1e;
}

.header {
  width: 100%;
  height: 310px;
  border-top: 2px solid #dcdcdc;
  border-bottom: 20px solid #dcdcdc;
  margin-bottom: 10px;
}

.logo {
  width: 100%;
  text-align: center;
}

.title {
  color: #941c80;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.subtitle {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 3px;
  font-size: 16px;
}

.base {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  width: 50%;
  background-color: #65ac1e;
  padding: 10px;
  border-radius: 4px;
}

.subheader {
  width: 100%;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 3px;
  font-size: 16px;
}

.text {
  width: 100%;
  font-size: 14px;
  color: white;
}

.inputlayer {
  padding-top: 10px;
}

.inputrow {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.inputrowlabel {
  min-width: 80px;
  width: 10%;
  display: flex;
  font-size: 14px;
  color: gray;
  font-weight: bold;
}

.buttonnok {
  border-radius: 2px;
  background-color: gray;
  color: white;
  border: 1px solid gray;
  margin-right: 10px;
}

.buttonok {
  border-radius: 2px;
  background-color: #941c80;
  color: white;
  border: 1px solid #941c80;
  background-image: none !important;
}

.cc {
  width: 100%;
  font-size: 12px;
  color: gray;
  text-align: right;
}

ul.nav {
  list-style: none;
  list-style-type: none;
}

ul.nav li {
  display: inline-block;
  padding: 5px;
}

.vue-form {
  font-size: 16px;
  width: 500px;
  padding: 15px 30px;
  border-radius: 4px;
  margin: 50px auto;
  width: 500px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
}

.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}
.vue-form div {
  position: relative;
  margin: 20px 0;
}
.vue-form h4,
.vue-form .label {
  color: #94aab0;
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}
.vue-form input,
.vue-form textarea,
.vue-form select,
.vue-form label {
  color: #2b3e51;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form select,
.vue-form legend {
  display: block;
  width: 100%;
  appearance: none;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form select {
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.vue-form .select {
  position: relative;
}
.vue-form .select::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Cpolygon%20fill%3D%22%232c3e50%22%20points%3D%220.9%2C5.5%203.1%2C3.4%208%2C8.3%2012.9%2C3.4%2015.1%2C5.5%208%2C12.6%20%09%22%2F%3E%0D%0A%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E")
    no-repeat center center;
  pointer-events: none;
}
.vue-form select {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.vue-form select::-ms-expand {
  display: none;
}
.vue-form .vue-form-list {
  margin-top: 16px;
}
.vue-form .vue-form-list::after {
  clear: both;
  content: "";
  display: table;
}
.vue-form .vue-form-list li {
  display: inline-block;
  position: relative;
  user-select: none;
  margin: 0 26px 16px 0;
  float: left;
}
.vue-form input[type="radio"],
.vue-form input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.vue-form input[type="radio"] + label,
.vue-form input[type="checkbox"] + label {
  padding-left: 24px;
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after {
  border-radius: 50%;
}
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.vue-form input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="radio"]:focus + label::before,
.vue-form input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::after {
  display: block;
}
.vue-form input[type="radio"]:checked + label::before,
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::before,
.vue-form input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}
.vue-form textarea {
  min-height: 120px;
  resize: vertical;
  overflow: auto;
}
.vue-form input[type="submit"] {
  border: none;
  background: #2c3e50;
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}
.no-touch .vue-form input[type="submit"]:hover {
  background: #42a2e1;
}
.vue-form input[type="submit"]:focus {
  outline: none;
  background: #2b3e51;
}
.vue-form input[type="submit"]:active {
  transform: scale(0.9);
}
.vue-form .error-message {
  height: 35px;
  margin: 0px;
}
.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}
.vue-form .error {
  border-color: #e94b35 !important;
}
.vue-form .counter {
  background-color: #ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}
</style>
