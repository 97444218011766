<template>
  <div>
    <div style="
        background: #fafafa;
        background-color: #fafafa;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #fafafa; background-color: #fafafa; width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div
                class="mj-column-px-400 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <tr>
                    <td
                      align="left"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <div style="
                          font-family: roboto, helvetica, arial, sans-serif;
                          font-size: 20px;
                          font-style: bold;
                          line-height: 1;
                          text-align: center;
                          color: #626262;
                        ">
                        {{ $t('app.tandc_header') }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div>
    <div>
      <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 800px        
      ">
        <!-- TODO: use the locale to choose the correct tandc to load -->
        <iframe
          :src="tandc"
          style="width:100%;height:600px;background: #fbfbfb;background-color: #fbfbfb;margin: 0px auto;"
        >
        </iframe>
      </div>
    </div>
  </div>

  <div>
    <div>
      <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 600px;
      ">
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          style="background: #fbfbfb; background-color: #fbfbfb; width: 100%"
        >
          <tbody>
            <tr>
              <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
                <div style="margin: 0px auto; max-width: 600px">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="width: 100%"
                  >
                    <tbody>
                      <tr>
                        <td style="
                          direction: ltr;
                          font-size: 0px;
                          padding: 20px 0;
                          text-align: center;
                          vertical-align: top;
                        ">
                          <div
                            class="mj-column-per-100 outlook-group-fix"
                            style="
                            font-size: 13px;
                            text-align: left;
                            direction: ltr;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                          "
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="vertical-align: top"
                              width="100%"
                            >
                              <tr>
                                <td style="
                                  font-size: 0px;
                                  padding: 10px 25px;
                                  word-break: break-word;
                                ">
                                  <p style="
                                    border-top: dotted 2px #941c80;
                                    font-size: 1;
                                    margin: 0px auto;
                                    width: 100%;
                                  "></p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="center"
                                  vertical-align="middle"
                                  style="
                                  font-size: 0px;
                                  padding: 10px 25px;
                                  word-break: break-word;
                                "
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="
                                    border-collapse: separate;
                                    line-height: 100%;
                                  "
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        bgcolor="#039be6"
                                        role="presentation"
                                        style="
                                        border: none;
                                        border-radius: 3px;
                                        cursor: auto;
                                        padding: 10px 25px;
                                        background: #039be6;
                                      "
                                        valign="middle"
                                      >
                                        <router-link
                                          to="/"
                                          style="background: #039be6;
                                          color: #ffffff;
                                          font-family: Ubuntu, Helvetica, Arial, sans-serif;
                                          font-size: 13px;
                                          font-weight: normal;
                                          line-height: 120%;
                                          margin: 0;
                                          text-decoration: none;
                                          text-transform: none;"
                                        >
                                          {{$t('app.button_OK')}}
                                        </router-link>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="mj-column-per-33 outlook-group-fix"
                  style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="vertical-align: top"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="center"
                        style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                      >
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                          style="border-collapse: collapse; border-spacing: 0px"
                        >
                          <tbody>
                            <tr>
                              <td style="width: 100px">
                                <img
                                  height="auto"
                                  src=""
                                  style="
                                  border: 0;
                                  display: block;
                                  outline: none;
                                  text-decoration: none;
                                  height: auto;
                                  width: 100%;"
                                  width="100"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function() {
    return {
      tandc_prefix: "docs/terms_and_conditions_LOCALE.html"
    };
  },
  computed: {
    tandc: function() {
      return this.tandc_prefix.replace(/LOCALE/i, this.$root.$i18n.locale);
    }
  }
};
</script>

<style scoped>
</style>