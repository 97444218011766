import { createApp } from 'vue';
import { createStore } from 'vuex';

import { i18n } from './translations';
import { router } from './routes';

import App from './App.vue'

const store = createStore({
    state: {
        showHeader: true,
        showFooter: true
    },
    getters: {
        showHeader: function (state) {
            return (state.showHeader);
        },

        showFooter: function (state) {
            return (state.showFooter);
        }
    },
    mutations: {
        changeIsBare: function (state, payload) {
            state.showHeader = !payload;
            state.showFooter = !payload;
        }
    },
    actions: {}
});

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .mount('#app')
