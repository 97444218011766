<template>
  <div>
    <div style="
        background: #000000;
        background-color: #000000;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #000000; background-color: #000000; width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div
                class="mj-column-per-50 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <!-- xarvio logo -->
                  <tr>
                    <td
                      align="center"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <table
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="border-collapse: collapse; border-spacing: 0px"
                      >
                        <tbody>
                          <tr>
                            <td style="width: 200px">
                              <img
                                height="auto"
                                src="@/assets/img/xarvio_logo.png"
                                style="
                                  border: 0;
                                  display: block;
                                  outline: none;
                                  text-decoration: none;
                                  height: auto;
                                  width: 100%;
                                "
                                width="200"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
              <div
                class="mj-column-per-50 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <tr>
                    <td
                      align="center"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <table
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="float: none; display: inline-table"
                      >
                        <tr>
                          <td style="padding: 4px">
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                background: #3f729b;
                                border-radius: 3px;
                                width: 20px;
                              "
                            >
                              <tr>
                                <td style="
                                    font-size: 0;
                                    height: 20px;
                                    vertical-align: middle;
                                    width: 20px;
                                  ">
                                  <a
                                    href="https://www.instagram.com/xarvio_de"
                                    target="_blank"
                                  ><img
                                      height="20"
                                      src="https://www.mailjet.com/images/theme/v1/icons/ico-social/instagram.png"
                                      style="border-radius: 3px"
                                      width="20"
                                    /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="float: none; display: inline-table"
                      >
                        <tr>
                          <td style="padding: 4px">
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                background: #55acee;
                                border-radius: 3px;
                                width: 20px;
                              "
                            >
                              <tr>
                                <td style="
                                    font-size: 0;
                                    height: 20px;
                                    vertical-align: middle;
                                    width: 20px;
                                  ">
                                  <a
                                    href="https://twitter.com/home?status=http://www.twitter.com/xarviodf"
                                    target="_blank"
                                  ><img
                                      height="20"
                                      src="https://www.mailjet.com/images/theme/v1/icons/ico-social/twitter.png"
                                      style="border-radius: 3px"
                                      width="20"
                                    /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="float: none; display: inline-table"
                      >
                        <tr>
                          <td style="padding: 4px">
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                background: #3b5998;
                                border-radius: 3px;
                                width: 20px;
                              "
                            >
                              <tr>
                                <td style="
                                    font-size: 0;
                                    height: 20px;
                                    vertical-align: middle;
                                    width: 20px;
                                  ">
                                  <a
                                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.facebook.com/xarvio.de/"
                                    target="_blank"
                                  ><img
                                      height="20"
                                      src="https://www.mailjet.com/images/theme/v1/icons/ico-social/facebook.png"
                                      style="border-radius: 3px"
                                      width="20"
                                    /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="float: none; display: inline-table"
                      >
                        <tr>
                          <td style="padding: 4px">
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="
                                background: #eb3323;
                                border-radius: 3px;
                                width: 20px;
                              "
                            >
                              <tr>
                                <td style="
                                    font-size: 0;
                                    height: 20px;
                                    vertical-align: middle;
                                    width: 20px;
                                  ">
                                  <a
                                    href="https://www.youtube.com/channel/UCkYW4v85mqepC37kJJFkOAw"
                                    target="_blank"
                                  ><img
                                      height="20"
                                      src="https://www.mailjet.com/images/theme/v1/icons/ico-social/youtube.png"
                                      style="border-radius: 3px"
                                      width="20"
                                    /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="
        background: #000000;
        background-color: #000000;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #000000; background-color: #000000; width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div
                class="mj-column-per-100 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <tr>
                    <td
                      align="right"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <div style="
                          font-family: roboto, helvetica, arial, sans-serif;
                          font-size: 13px;
                          line-height: 1;
                          text-align: right;
                          color: #ffffff;
                        ">
                        xarvio Reliable Fusarium Control for Revytop © 2021
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>