const config_def = {
    DEV: {
        invokeUrl: "https://557pcxny34.execute-api.eu-central-1.amazonaws.com/dev0"
    },
    QA: {
        invokeUrl: "https://j2jei05ubd.execute-api.eu-central-1.amazonaws.com/qa0"
    },
    PROD: {
        invokeUrl: "https://26fsuj8d1l.execute-api.eu-central-1.amazonaws.com/prod0"
    }
};

export default config_def;