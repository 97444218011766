import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        app: {
            title: 'xarvio Reliable Fusarium Control for Revytop 2021',
            description_title: 'xarvio is proud to share into Your risk',
            description: 'xarvio Reliable Fusarium Control for Revytop is the modern, hassle-free, and trusted way to plan your T3 Revytop application together with us. We share into your concerns and risk, so that you can concentrate on your field and yield.',
            how: 'How does it work?',
            how_1_step: 'Sign up for free with xarvio FIELD MANAGER',
            how_1_description_pre: 'Go to ',
            how_1_description_post: ', register for FIELD MANAGER and apply the voucher code to redeem your personal 50% FIELD MANAGER discount.',
            how_2_step: 'Setup your 2021 Season',
            how_2_description: 'Set up your field(s) where you plan to grow winter wheat and provide information as required.',
            how_3_step: 'Enroll for refund',
            how_3_description: 'Register your BASF Revytop purchase before end of May to be entitled to a potential 50% refund.',
            how_4_step: 'For reliable Fusarium control Apply BASF Revytop via xarvio Spray Timer',
            how_4_description: 'Treat your field(s) with BASF Revytop based on xarvio Field Managers Spray Timer advice (at the beginning of flowering (T3) - growth stage is between 61 – 69) with the recommended dose rate (Revytop = Sulki + Simveris 0,5 + 1,0L/ha).',
            how_5_step: 'Have a great yield or be refunded',
            how_5_description: 'We measure your risk of being infected with Fusarium. If xFM spray timer indicates a high risk of Fusarium, but accumulated rainfall from flowering to harvest time, on your fields will remain below 10mm, we refund you!',
            button_tandc: 'Terms and Conditions',
            button_signup: 'Yes, Sign me Up!',
            contactus_pre: 'If you do have any questions contact us under',
            contactus_post: ', or alternatively you can reach us under +48 22 570 99 90.',
            button_register: 'Register',
            invalidUserDetails: 'Please check the information you provided!',
            tandc_header: 'Terms and Conditions',
            button_OK: 'OK',
            signup_userMail: 'Your Field Manager E-Mail',
            signup_userName: 'Your Name',
            signup_phone: 'Your Telefone Number',
            signup_taxAccountNumber: 'Your Tax Account Number',
            signup_accept_xFM: 'I accept the xFM Terms and Conditions',
            signup_accept_xRFC: 'I accept the xRFC Terms and Conditions',
            signup_accept_GDPR: 'I accept the GDPR Agreement',
            signup_accept_Marketing: 'I accept the additional marketing agreement'
        }
    },
    de: {
        app: {
            title: 'xarvio Reliable Fusarium Control für Revytop 2021',
            description_title: 'xarvio is proud to share into Your risk',
            description: `xarvio Reliable Fusarium Control for Revytop is the
            modern, hassle-free, and trusted way to plan your T3
            Revytop application together with us. We share into your
            concerns and risk, so that you can concentrate on your
            field and yield.`,
            how: 'How does it work?',
            how_1_step: 'Sign up for free with xarvio FIELD MANAGER',
            how_1_description_pre: 'Go to ',
            how_1_description_post: ', register for FIELD MANAGER and apply the voucher code to redeem your personal 50% FIELD MANAGER discount.',
            how_2_step: 'Setup your 2021 Season',
            how_2_description: 'Set up your field(s) where you plan to grow winter wheat and provide information as required.',
            how_3_step: 'Enroll for refund',
            how_3_description: 'Register your BASF Revytop purchase before end of May to be entitled to a potential 50% refund.',
            how_4_step: 'For reliable Fusarium control Apply BASF Revytop via xarvio Spray Timer',
            how_4_description: 'Treat your field(s) with BASF Revytop based on xarvio Field Managers Spray Timer advice (at the beginning of flowering (T3) - growth stage is between 61 – 69) with the recommended dose rate (Revytop = Sulki + Simveris 0,5 + 1,0L/ha).',
            how_5_step: 'Have a great yield or be refunded',
            how_5_description: 'We measure your risk of being infected with Fusarium. If xFM spray timer indicates a high risk of Fusarium, but accumulated rainfall from flowering to harvest time, on your fields will remain below 10mm, we refund you!',
            button_tandc: 'Geschäftsbedingungen',
            button_signup: 'Ja, ich will dabei sein!',
            contactus_pre: 'If you do have any questions contact us under ',
            contactus_post: ', or alternatively you can reach us under +48 22 570 99 90.',
            button_register: 'Anmelden',
            invalidUserDetails: 'Bitte prüfen Sie ihre Eingaben!',
            tandc_header: 'Geschäftsbedingungen',
            button_OK: 'OK',
            signup_userMail: 'Deine Field Manager E-Mail',
            signup_userName: 'Dein Name',
            signup_phone: 'Deine Telefonnummer',
            signup_taxAccountNumber: 'Steuernummer',
            signup_accept_xFM: 'Ich stimme den xFM Geschäftsbedingungen zu.',
            signup_accept_xRFC: 'Ich stimme den xRFC Geschäftsbedingungen zu.',
            signup_accept_GDPR: 'Ich stimme den GDPR Richtlinien zu.',
            signup_accept_Marketing: 'Ich stimme den Marketing Bedingungen zu.'
        }
    },
    pl: {
        app: {
            title: 'Niezawodna kontrola fuzarium z xarvio i RevyTop w 2021',
            description_title: 'podziel się z xarvio swoim ryzykiem',
            description: 'Niezawodna kontrola fuzarium z xarvio i RevyTop to nowoczesne podejście do zabezpieczenia Twoich plonów i planowania zabiegów T3 w pszenicy ozimej. Skup się na swoich uprawach i plonach dzieląc z xarvio ryzyko związane z presją fuzarium.',
            how: 'Jak to działa',
            how_1_step: 'Zarejestruj się bezpłatnie w xarvio FIELD MANAGER',
            how_1_description_pre: 'Idź do ',
            how_1_description_post: ' zarejestruj się w FIELD MANAGER i zrealizuj swój indywidualny kod zniżkowy. Zakup wersje Premium FIELD MANAGER z rabatem 50%.',
            how_2_step: 'Skonfiguruj swój sezon 2021',
            how_2_description: 'Skonfiguruj swoje pola, na których planujesz uprawę pszenicy ozimej i wprowadź wymagane informacje.',
            how_3_step: 'Zarejestruj się aby otrzymać zwrot',
            how_3_description: 'Zakup RevyTop przed 24.05.2021 i prześlij fakturę do BeneFito do końca czerwca aby otrzymać potencjalny zwrot 50% zakupionego RevyTop.',
            how_4_step: 'Aby zapewnić skuteczną ochronę przed fuzarium, zastosuj RevyTop na swoim polu i dodaj zabieg w terminarzu zabiegów xarvio FIELD MANAGER',
            how_4_description: 'Zastosuj RevyTop na swoim polu i dodaj zabieg w terminarzu zabiegów xarvio FIELD MANAGER zgodnie z zalecaną dawką Sulky+Simveris (0,5 + 1,0 L/ha) w fazie rozwojowej BBCH 60-69.',
            how_5_step: 'Uzyskaj wysoki plon albo otrzymaj zwrot',
            how_5_description: 'Mierzymy ryzyko wystąpienia fuzarium. Jeżeli terminarz zabiegów xarvio FIELD MANAGER wskaże wysokie ryzyko zakażenia fuzarium, ale skumulowane opady od momentu zastosowania RevyTop przez kolejne 4 tygodnie będą niższe niż 10mm zwrócimy Ci 50% zakupionego produktu.',
            button_tandc: 'Zasady i Regulamin',
            button_signup: 'Tak, zarejestruj mnie',
            contactus_pre: 'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami ',
            contactus_post: ' ewentualnie zadzwoń do nas na numer +48 22 570 99 90.',
            button_register: 'Zarejestruj się',
            invalidUserDetails: 'Sprawdź wprowadzone dane',
            tandc_header: 'Zasady i Regulamin',
            button_OK: 'OK',
            signup_userMail: 'Adres e-mail do Twojego xarvio FIELD MANAGERA',
            signup_userName: 'Imię',
            signup_phone: 'numer Telefonu',
            signup_taxAccountNumber: 'numer NIP',
            signup_accept_xFM: 'oświadczam, że zapoznałam/em się z treścią udostępnionych mi Warunków Gwarancji Programu xarvio® Field Manager i je akceptuję.',
            signup_accept_xRFC: 'deklaruję chęć udziału w Programie XFM na warunkach wskazanych w udostępnionych mi Warunkach Gwarancji Programu xarvio® Field Manager.',
            signup_accept_GDPR: 'potwierdzam otrzymanie oraz akceptuję treść Informacji nt. przetwarzania moich danych osobowych przez BASF Digital Farming GmbH oraz BASF Polska sp. z o.o. (podmioty zaangażowane w organizację Programu XFM).',
            signup_accept_Marketing: 'wyrażam zgodę na przetwarzanie moich danych osobowych przez BASF Digital Farming GmbH i BASF Polska sp. z o.o. w sposób określony w Informacji nt. przetwarzania danych osobowych Uczestników Programu XFM i upoważniam BASF Polska sp. z o.o. do przekazania na potrzeby Programu XFM spółce BASF Digital Farming GmbH niezbędnych informacji zawartych w Dowodzie Zakupu w rozumieniu Warunków Gwarancji Programu xarvio® Field Manager (tj. daty i ilości zakupu Revytop wraz z numerem NIP/REGON nabywcy) zarejestrowanym przeze mnie w ramach programu BeneFito, na zasadach wskazanych w Warunkach Gwarancji Programu xarvio® Field Manager.'
        }
    }
}


const i18n = createI18n({
    locale: 'pl', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
    WarnHtmlInMessageLevel: 'off'
})

export { i18n };