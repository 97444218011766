<template>
  <div>
    <div style="
        background: #fafafa;
        background-color: #fafafa;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #fafafa; background-color: #fafafa; width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div
                class="mj-column-px-400 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <tr>
                    <td
                      align="left"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <div style="
                          font-family: roboto, helvetica, arial, sans-serif;
                          font-size: 20px;
                          font-style: bold;
                          line-height: 1;
                          text-align: left;
                          color: #626262;
                        ">
                        <!-- xarvio is proud to share into Your risk -->
                        Send User Communication
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      align="left"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <div style="
                          font-family: roboto, helvetica, arial, sans-serif;
                          font-size: 13px;
                          line-height: 1;
                          text-align: left;
                          color: #525252;
                        ">
                        After our customers have a accepted the terms and conditions, we need to send them their personal voucher to activate xarvio FIELD MANAGER Premium.
                        Using this form you can send this personal communication to your customer. Please provide the first name and the farmers email.
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 800px        
      ">
      <div class="text">
        <p>
          <input
            type="text"
            id="iName"
            name="iName"
            class="textInput"
            placeholder="First Name"
            title="Please enter customer's first name."
            v-model="userName"
          />
        </p>
        <p>
          <input
            type="email"
            id="iEmail"
            name="iEmail"
            class="textInput"
            title="Please enter the customer's e-mail address that is associated with your Field Manager account."
            v-model="userEmail"
            placeholder="Customer Email"
          />
        </p>
        <p>
          <select
            name="sCommunication"
            id="sCommunication"
            title="Please select the type of communication you wish to have with your customer"
            v-model="selectedCommunication"
          >
            <option
              v-for="copt in communicationOptions"
              :key="copt"
              :value="copt"
            >{{copt}}</option>
          </select>
        </p>
      </div>
    </div>

    <div style="
        background: #fbfbfb;
        background-color: #fbfbfb;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #fbfbfb; background-color: #fbfbfb; width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 10px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div style="margin: 0px auto; max-width: 600px">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="width: 100%"
                >
                  <tbody>
                    <tr style="align: center;">
                      <td style="
                          direction: ltr;
                          font-size: 0px;
                          padding: 10px 20px;
                          text-align: center;
                          vertical-align: top;
                        ">
                        <div
                          class="mj-column-per-100 outlook-group-fix"
                          style="
                            font-size: 13px;
                            text-align: left;
                            direction: ltr;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                          "
                        >
                          <table
                            class="tableCenter"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            style="vertical-align: top; horizontal-align: center;"
                            width="50%"
                          >
                            <tr>
                              <td
                                align="center"
                                bgcolor="#039be6"
                                role="presentation"
                                :class="sendReady"
                                style="border: none;
                                        border-radius: 3px;
                                        cursor: auto;
                                        padding: 10px 25px;"
                                valign="middle"
                              >
                                <button
                                  :class="sendReady"
                                  style="font-family: Ubuntu, Helvetica, Arial, sans-serif;
                                  font-size: 13px;
                                  font-weight: normal;
                                  line-height: 120%;
                                  margin: 0;
                                  border-width: 0px;
                                  text-decoration: none;
                                  text-transform: none;"
                                  :disabled="!sendButtonEnabled"
                                  @click="communicateUser()"
                                >Send Communication</button>

                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import apgClientFactory from "aws-api-gateway-client";
import config_def from "../modules/env_def";
import { env } from "../modules/env_set";

const config = config_def[env.toUpperCase()];
var apigClient = apgClientFactory.newClient(config);

export default {
  components: {},
  data: function() {
    return {
      userName: null,
      userEmail: null,
      communicationOptions: ["Send Voucher"],
      selectedCommunication: "Send Voucher"
    };
  },
  computed: {
    sendButtonEnabled: function() {
      // TODO: if we have a first name and a user email
      const have_userName = this.userName != null && this.userName.trim() != "";
      const have_userEMail =
        this.userEmail != null && this.userEmail.trim() != "";
      const enabled = have_userName && have_userEMail;
      return enabled;
    },
    sendReady: function() {
      return this.sendButtonEnabled ? "enabled" : "disabled";
    }
  },
  methods: {
    validateUserInput: function() {
      if (!this.sendButtonEnabled) return;
      if (this.userName == null || this.userName.trim() == "") {
        alert("Please provide your name");
      }
      if (this.userEmail == null || this.userEmail.trim() == "") {
        alert("Please provide your xarvio Field Manager E-mail");
      }

      return true;
    },
    communicateUser: function() {
      this.validateUserInput();

      var additionalParams = {};
      // TODO: set the user data and the correct endpoint
      var body = {
        "xrfc-user-firstname": this.userName,
        "xrfc-user-email": this.userEmail,
        "xrfc-user-country-iso2": this.$root.$i18n.locale,
        "xrfc-communication-request": this.selectedCommunication
      };

      const pathParams = {};
      // Template syntax follows url-template https://www.npmjs.com/package/url-template
      const pathTemplate = "/xrfcm";
      const method = "POST";

      apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function(error) {
          alert("Communication sent ...");
          console.log(error);
          self.close();
        })
        .catch(function(error) {
          alert("Something went wrong ...");
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.tableCenter {
  margin-left: auto;
  margin-right: auto;
}

.name {
  font-weight: bold;
  text-align: right;
  padding-right: 5px;
  color: black;
}

.light-name {
  text-align: right;
  padding-right: 5px;
  color: black;
}

.text .textInput {
  width: 200px;
}

.checkboxes label {
  display: block;
  padding-right: 5px;
  padding-left: 30px;
  text-indent: -10px;
  padding-bottom: 5px;
}

.checkboxes input {
  vertical-align: middle;
}

.checkboxes label span {
  vertical-align: middle;
  flex: 1;
  text-align: left;
  font-family: roboto, helvetica, arial, sans-serif;
  font-size: 13px;
  line-height: 0;
  color: rgb(82, 82, 82);
}

.enabled {
  background: #039be6;
}

.disabled {
  background: #bababa;
}
</style>