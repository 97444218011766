<template>
  <div>
    <div style="
        background: #f0f0f0;
        background-color: #f0f0f0;
        margin: 0px auto;
        max-width: 600px;
      ">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="background: #f0f0f0; background-color: #f0f0f0; width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div
                class="mj-column-per-100 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <tr>
                    <td
                      align="center"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <div style="
                          font-family: roboto, helvetica, arial, sans-serif;
                          font-size: 20px;
                          font-style: bold;
                          line-height: 1;
                          text-align: center;
                          color: #941c80;
                        ">
                        <!-- xarvio Reliable Fusarium Control for Osiris 2021 -->
                        {{ $t("app.title") }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="margin: 0px auto; max-width: 600px">
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="width: 100%"
      >
        <tbody>
          <tr>
            <td style="
                direction: ltr;
                font-size: 0px;
                padding: 20px 0;
                text-align: center;
                vertical-align: top;
              ">
              <div
                class="mj-column-px-600 outlook-group-fix"
                style="
                  font-size: 13px;
                  text-align: left;
                  direction: ltr;
                  display: inline-block;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="vertical-align: top"
                  width="100%"
                >
                  <!-- xarvio header: gfx/xarvio_header.png -->
                  <tr>
                    <td
                      align="center"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <table
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="border-collapse: collapse; border-spacing: 0px"
                      >
                        <tbody>
                          <tr>
                            <td style="width: 550px">
                              <img
                                height="auto"
                                src="@/assets/img/xarvio_header.png"
                                style="
                                  border: 0;
                                  display: block;
                                  outline: none;
                                  text-decoration: none;
                                  height: auto;
                                  width: 100%;
                                "
                                width="550"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      align="center"
                      style="
                        font-size: 0px;
                        padding: 10px 25px;
                        word-break: break-word;
                      "
                    >
                      <div style="
                          font-family: Helvetica Neue;
                          font-size: 40px;
                          line-height: 1;
                          text-align: center;
                          color: #ffffff;
                        "></div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>